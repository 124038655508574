import { Icon } from "leaflet"
import { Marker, TileLayer, useMap } from "react-leaflet"
import markerIcon from "../assets/redMarker.png"
import markerIconSelected from "../assets/blueMarker.png"
import markerIconGreen from "../assets/greenMarker.png"
import setUpMarkers from "./Marker"
import { useEffect, useState } from "react"

export default function LocationMap({
  locations,
  setSelectedLocation,
  selectedLocation,
  day,
}) {
  const [loadedMarkers, setLoadedMarkers] = useState(false)

  const map = useMap()

  useEffect(() => {
    setUpMarkers()
    setLoadedMarkers(true)
  }, [])

  if (!loadedMarkers) return

  const currentResults = JSON.parse(localStorage.getItem("results")) ?? {}

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {locations.map((l) => {
        const alreadyPlayed = currentResults?.[day]?.[l.key]

        return (
          <Marker
            key={l.key}
            position={[l.latitude, l.longitude]}
            data={l.key}
            icon={
              new Icon({
                iconUrl:
                  l.key === selectedLocation?.key
                    ? markerIconSelected
                    : alreadyPlayed
                    ? markerIcon
                    : markerIconGreen,
                iconSize: [24, 24],
                iconAnchor: [12, 24],
              })
            }
            eventHandlers={{
              click: (e) => {
                const latlng = e.latlng
                map.panTo([latlng.lat, latlng.lng], { duration: 1 })

                const selectedLoc = e.target.options.data

                const location = locations.filter((l) => l.key === selectedLoc)

                if (location.length !== 1) return

                setSelectedLocation(location[0])
              },
            }}
          ></Marker>
        )
      })}
    </>
  )
}
