import { useState } from "react"
import CountUp from "react-countup"
import { Link, useNavigate } from "react-router-dom"
import AuxiliaryButton from "../Button/AuxiliaryButton"
import BlueButton from "../Button/BlueButton"

export default function GuessModalBody({
  guess = 0,
  actual = 0,
  index,
  onClose,
  setModalState,
  setGuess,
  url,
  day,
}) {
  const navigate = useNavigate()

  const [showAmountOff, setShowAmountOff] = useState(false)

  const amountOff = parseInt(guess) - parseInt(actual)

  const amountOffString =
    amountOff < 0
      ? amountOff.toLocaleString("en-UK").replace("-", "-£")
      : `+£${amountOff.toLocaleString("en-UK")}`

  const onSubmit = () => {
    setGuess("")
    setShowAmountOff(false)

    // if (index == 0) {
    //   localStorage.removeItem("results")
    // }

    const urlParams = new URLSearchParams(window.location.search)
    const location = urlParams.get("location")

    const currentResults = JSON.parse(localStorage.getItem("results")) ?? {}

    const currentLocResults = currentResults?.[location] ?? []

    const newResults = [
      ...currentLocResults,
      {
        guess,
        actual: actual.toString(),
        amountOff: amountOffString,
        actualAmountOff: amountOff,
      },
    ]

    localStorage.setItem(
      "results",
      JSON.stringify({
        ...currentResults,
        [day]: {
          ...currentResults[day],
          [location]: newResults,
        },
      })
    )

    if (index == 0) {
      setModalState("result")
      return
    }

    onClose()

    navigate(`/play?location=${location}&index=${parseInt(index) + 1}`)
  }

  return (
    <div>
      <div className="flex flex-col space-y-8">
        <div className="flex flex-col items-center justify-center">
          <p className="mb-2 text-3xl font-['Alfa_Slab_One'] text-gray-800">
            £
            <CountUp
              end={actual}
              separator=","
              onEnd={() => setShowAmountOff(true)}
            />
          </p>
          <p className="font-semibold text-gray-500">Actual Price</p>
        </div>
        <div className="flex justify-center md:space-x-8 space-y-8 md:space-y-0 flex-col md:flex-row">
          <div className="flex flex-col items-center justify-center">
            <p className="mb-2 text-3xl font-['Alfa_Slab_One'] text-gray-800">
              £{parseInt(guess).toLocaleString("en-UK")}
            </p>
            <p className="font-semibold text-gray-500">Your Guess</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="mb-2 text-3xl font-['Alfa_Slab_One'] text-gray-800">
              {showAmountOff ? amountOffString : "£??????"}
            </p>
            <p className="font-semibold text-gray-500">Amount Off</p>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-8 space-x-4">
        <a target="_blank" rel="noreferrer" href={url}>
          <AuxiliaryButton>View On Rightmove</AuxiliaryButton>
        </a>
        <BlueButton onClick={onSubmit}>Next</BlueButton>
      </div>
    </div>
  )
}
