import { ReactComponent as Logo } from "../assets/home-question.svg"
import { Link } from "react-router-dom"

export default function Root() {
  return (
    <div className="bg-black w-full absolute inset-0 md:py-8">
      <div className="m-0 mx-auto max-w-lg h-full bg-[url('../public/bg-houses.png')] bg-center overflow-hidden">
        <div className="flex justify-center items-center h-full relative">
          <div className="left-4 top-4 absolute">
            <Logo />
          </div>
          <div className="text-center mx-16">
            <h1 className="text-3xl font-['Alfa_Slab_One'] text-gray-100 md:text-[2.8rem]">
              PROPERTYGUESSR
            </h1>
            <p className="font-sans font-normal text-2xl text-gray-100 mt-2 md:text-3xl">
              Guess the price of a recently sold home in locations across the
              UK.
            </p>
            <Link to="/locations">
              <button
                type="button"
                className="mt-8 text-gray-100 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-lg text-xl px-8 py-4 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                Play Free Now
              </button>
            </Link>
            <p className="text-gray-400 text-lg text-center font-semibold absolute left-0 bottom-4 w-full">
              Built by &nbsp;
              <a
                href="https://www.fraserhamilton.dev/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold hover:text-blue-700 hover:underline"
              >
                Fraser Hamilton
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
