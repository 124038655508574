/* eslint-disable react/prop-types */
import { FreeMode, Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react"
import { useEffect, useState } from "react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import classNames from "classnames"

export default function Carousel({ images }) {
  const [selectedSlide, setSelectedSlide] = useState(0)
  const [swiperRef, setSwiperRef] = useState(null)

  useEffect(() => {
    if (!swiperRef) return
    swiperRef.slideTo(0, 0, true)
    setSelectedSlide(0)
  }, [images])

  return (
    <Swiper
      onSwiper={setSwiperRef}
      spaceBetween={0}
      slidesPerView={1}
      navigation={{
        nextEl: ".next",
        prevEl: ".previous",
      }}
      pagination={{
        type: "fraction",
        renderFraction: function (currentClass, totalClass) {
          return `<div class="${"flex justify-center pb-2"}">
            <div class="${"bg-white border border-[#95999E] w-20 py-1 rounded-lg"}">
              <span class="${currentClass} text-base"></span>
              of
              <span class="${totalClass} text-base"></span>
            </div></div>`
        },
      }}
      onSlideChange={(ev) => setSelectedSlide(ev.activeIndex)}
      modules={[Navigation, Pagination]}
    >
      {images.map((i) => (
        <SwiperSlide key={i}>
          <div className="bg-white flex justify-center">
            <img
              style={{ maxHeight: "45rem" }}
              className="object-cover"
              src={i}
            />
          </div>
        </SwiperSlide>
      ))}

      <div
        className={classNames(
          `absolute top-0 z-30 flex items-center justify-center h-full px-4 focus:outline-none left-0`,
          {
            hidden: selectedSlide === 0,
          }
        )}
      >
        <span
          className={`bg-white border border-[#95999E] focus:outline-none hover:bg-gray-100 focus:ring-4 
            focus:ring-gray-200 inline-flex items-center justify-center w-10 h-10 rounded-lg cursor-pointer previous`}
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5 sm:w-6 sm:h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </span>
      </div>

      <div
        className={classNames(
          `absolute top-0 z-30 flex items-center justify-center h-full px-4 focus:outline-none right-0`,
          {
            hidden: selectedSlide === images.length - 1,
          }
        )}
      >
        <span
          className={`bg-white border border-[#95999E] focus:outline-none hover:bg-gray-100 focus:ring-4 
            focus:ring-gray-200 inline-flex items-center justify-center w-10 h-10 rounded-lg cursor-pointer next`}
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5 sm:w-6 sm:h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
          <span className="sr-only">Next</span>
        </span>
      </div>
      <div className="bg-white text-xl h-2 absolute bottom-0 left-0 w-full rounded-t-xl z-10" />
    </Swiper>
  )
}
