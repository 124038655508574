import classNames from "classnames"

const IconButton = ({ onClick, disabled, children }) => (
  <button
    onClick={onClick}
    type="button"
    className={classNames(
      `text-gray-900 flex-1 flex items-center justify-center bg-white border border-gray-300 focus:outline-none focus:ring-4
      focus:ring-gray-200 font-medium rounded-lg text-sm px-4`,
      {
        "cursor-not-allowed": disabled,
        "hover:bg-gray-100": !disabled,
      }
    )}
    disabled={disabled}
  >
    <div className={classNames({ "opacity-20": disabled })}>{children}</div>
  </button>
)

export default IconButton
