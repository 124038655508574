const locations = [
  {
    key: "edinburgh",
    title: "Edinburgh",
    url: "https://www.rightmove.co.uk/house-prices/edinburgh-city-of.html?soldIn=1&radius=0.25",
    latitude: 55.9533,
    longitude: -3.1883,
  },
  {
    key: "aberdeen",
    title: "Aberdeen",
    url: "https://www.rightmove.co.uk/house-prices/aberdeen-city-centre-94487.html?country=england&searchLocation=Aberdeen+City+Centre",
    latitude: 57.147,
    longitude: -2.0946,
  },
  {
    key: "bath",
    title: "Bath",
    url: "https://www.rightmove.co.uk/house-prices/bath.html?country=england&searchLocation=Bath",
    latitude: 51.3811,
    longitude: -2.3595,
  },
  {
    key: "birmingham",
    title: "Birmingham",
    url: "https://www.rightmove.co.uk/house-prices/birmingham.html?",
    latitude: 52.4819,
    longitude: -1.9034,
  },
  {
    key: "blackpool",
    title: "Blackpool",
    url: "https://www.rightmove.co.uk/house-prices/blackpool.html?country=england&searchLocation=Blackpool",
    latitude: 53.816699,
    longitude: -3.037149,
  },
  {
    key: "bradford",
    title: "Bradford",
    url: "https://www.rightmove.co.uk/house-prices/bradford.html?country=england&searchLocation=Bradford",
    latitude: 53.7938,
    longitude: -1.753,
  },
  {
    key: "brighton-and-hove",
    title: "Brighton",
    url: "https://www.rightmove.co.uk/house-prices/brighton-and-hove.html?",
    latitude: 50.8214,
    longitude: -0.1416,
  },
  {
    key: "bristol",
    title: "Bristol",
    url: "https://www.rightmove.co.uk/house-prices/bristol-city-centre.html?radius=2.0",
    latitude: 51.4529,
    longitude: -2.5972,
  },
  {
    key: "cambridge",
    title: "Cambridge",
    url: "https://www.rightmove.co.uk/house-prices/cambridge.html?radius=1.0",
    latitude: 52.2053,
    longitude: 0.1191,
  },
  {
    key: "canterbury",
    title: "Canterbury",
    url: "https://www.rightmove.co.uk/house-prices/canterbury.html?",
    latitude: 51.2812,
    longitude: 1.0794,
  },
  {
    key: "cardiff",
    title: "Cardiff",
    url: "https://www.rightmove.co.uk/house-prices/cardiff.html?country=england&searchLocation=Cardiff",
    latitude: 51.4818,
    longitude: -3.1785,
  },
  {
    key: "carlisle",
    title: "Carlisle",
    url: "https://www.rightmove.co.uk/house-prices/carlisle.html?country=england&searchLocation=Carlisle",
    latitude: 54.8979,
    longitude: -2.9361,
  },
  {
    key: "chelmsford",
    title: "Chelmsford",
    url: "https://www.rightmove.co.uk/house-prices/chelmsford.html?",
    latitude: 51.7347,
    longitude: 0.4717,
  },
  {
    key: "chester",
    title: "Chester",
    url: "https://www.rightmove.co.uk/house-prices/chester.html?country=england&searchLocation=Chester",
    latitude: 53.1912,
    longitude: -2.8911,
  },
  {
    key: "chichester",
    title: "Chichester",
    url: "https://www.rightmove.co.uk/house-prices/chichester.html?country=england&searchLocation=Chichester",
    latitude: 50.8366,
    longitude: -0.7792,
  },
  {
    key: "colchester",
    title: "Colchester",
    url: "https://www.rightmove.co.uk/house-prices/colchester.html?country=england&searchLocation=Colchester",
    latitude: 51.8898,
    longitude: 0.8994,
  },
  {
    key: "coventry",
    title: "Coventry",
    url: "https://www.rightmove.co.uk/house-prices/coventry.html?radius=0.25",
    latitude: 52.4083,
    longitude: -1.511,
  },
  {
    key: "derby",
    title: "Derby",
    url: "https://www.rightmove.co.uk/house-prices/derby.html?country=england&searchLocation=Derby",
    latitude: 52.9212,
    longitude: -1.4765,
  },
  {
    key: "dundee",
    title: "Dundee",
    url: "https://www.rightmove.co.uk/house-prices/dundee.html?country=england&searchLocation=Dundee",
    latitude: 56.4603,
    longitude: -2.9701,
  },
  {
    key: "dunfermline",
    title: "Dunfermline",
    url: "https://www.rightmove.co.uk/house-prices/dunfermline.html?radius=0.5",
    latitude: 56.0717,
    longitude: -3.4615,
  },
  {
    key: "durham",
    title: "Durham",
    url: "https://www.rightmove.co.uk/house-prices/durham.html?country=england&searchLocation=Durham",
    latitude: 54.7771,
    longitude: -1.5757,
  },
  {
    key: "exeter",
    title: "Exeter",
    url: "https://www.rightmove.co.uk/house-prices/exeter.html?radius=0.25",
    latitude: 50.7252,
    longitude: -3.5273,
  },
  {
    key: "glasgow",
    title: "Glasgow",
    url: "https://www.rightmove.co.uk/house-prices/glasgow-city-centre.html?radius=2.0",
    latitude: 55.8613,
    longitude: -4.2489,
  },
  {
    key: "gloucester",
    title: "Gloucester",
    url: "https://www.rightmove.co.uk/house-prices/gloucester.html?radius=0.5",
    latitude: 51.8663,
    longitude: -2.2472,
  },
  {
    key: "harrogate",
    title: "Harrogate",
    url: "https://www.rightmove.co.uk/house-prices/harrogate.html?country=england&searchLocation=Harrogate",
    latitude: 53.991974,
    longitude: -1.542146,
  },
  {
    key: "hereford",
    title: "Hereford",
    url: "https://www.rightmove.co.uk/house-prices/hereford.html?radius=1.0",
    latitude: 52.053,
    longitude: -2.7236,
  },
  {
    key: "inverness",
    title: "Inverness",
    url: "https://www.rightmove.co.uk/house-prices/inverness.html?country=england&searchLocation=Inverness",
    latitude: 57.4794,
    longitude: -4.2257,
  },
  {
    key: "kingston-upon-hull",
    title: "Hull",
    url: "https://www.rightmove.co.uk/house-prices/kingston-upon-hull.html?country=england&searchLocation=Kingston+Upon+Hull",
    latitude: 53.7439,
    longitude: -0.3395,
  },
  {
    key: "lancaster",
    title: "Lancaster",
    url: "https://www.rightmove.co.uk/house-prices/lancaster.html?radius=1.0",
    latitude: 54.0437,
    longitude: -2.8015,
  },
  {
    key: "leeds",
    title: "Leeds",
    url: "https://www.rightmove.co.uk/house-prices/leeds.html?country=england&searchLocation=Leeds",
    latitude: 53.7946,
    longitude: -1.5456,
  },
  {
    key: "leicester",
    title: "Leicester",
    url: "https://www.rightmove.co.uk/house-prices/leicester.html?country=england&searchLocation=Leicester",
    latitude: 52.6365,
    longitude: -1.1332,
  },
  {
    key: "lichfield",
    title: "Lichfield",
    url: "https://www.rightmove.co.uk/house-prices/lichfield.html?radius=1.0",
    latitude: 52.6842,
    longitude: -1.828,
  },
  {
    key: "lincoln",
    title: "Lincoln",
    url: "https://www.rightmove.co.uk/house-prices/lincoln.html?radius=0.25",
    latitude: 53.2293,
    longitude: -0.5409,
  },
  {
    key: "liverpool",
    title: "Liverpool",
    url: "https://www.rightmove.co.uk/house-prices/liverpool.html?radius=1.0",
    latitude: 53.4065,
    longitude: -2.9924,
  },
  {
    key: "london",
    title: "London",
    url: "https://www.rightmove.co.uk/house-prices/london-87490.html?country=england&searchLocation=London",
    latitude: 51.5045,
    longitude: -0.1291,
  },
  {
    key: "manchester",
    title: "Manchester",
    url: "https://www.rightmove.co.uk/house-prices/manchester.html?country=england&searchLocation=Manchester",
    latitude: 53.4795,
    longitude: -2.2467,
  },
  {
    key: "milton-keynes",
    title: "Milton Keynes",
    url: "https://www.rightmove.co.uk/house-prices/milton-keynes.html?country=england&searchLocation=Milton+Keynes",
    latitude: 52.0405,
    longitude: -0.7598,
  },
  {
    key: "newcastle-upon-tyne",
    title: "Newcastle",
    url: "https://www.rightmove.co.uk/house-prices/newcastle-upon-tyne.html?country=england&searchLocation=Newcastle+Upon+Tyne",
    latitude: 54.9739,
    longitude: -1.6133,
  },
  {
    key: "newport",
    title: "Newport",
    url: "https://www.rightmove.co.uk/house-prices/newport.html?radius=0.5",
    latitude: 51.5884,
    longitude: -2.9972,
  },
  {
    key: "norwich",
    title: "Norwich",
    url: "https://www.rightmove.co.uk/house-prices/norwich.html?radius=0.5",
    latitude: 52.6291,
    longitude: 1.2916,
  },
  {
    key: "nottingham",
    title: "Nottingham",
    url: "https://www.rightmove.co.uk/house-prices/nottingham.html?country=england&searchLocation=Nottingham",
    latitude: 52.953,
    longitude: -1.1508,
  },
  {
    key: "oxford",
    title: "Oxford",
    url: "https://www.rightmove.co.uk/house-prices/oxford.html?",
    latitude: 51.752,
    longitude: -1.2586,
  },
  {
    key: "perth",
    title: "Perth",
    url: "https://www.rightmove.co.uk/house-prices/perth.html?radius=1.0",
    latitude: 56.3968,
    longitude: -3.4339,
  },
  {
    key: "peterborough",
    title: "Peterborough",
    url: "https://www.rightmove.co.uk/house-prices/peterborough.html?radius=0.25",
    latitude: 52.5734,
    longitude: -0.2431,
  },
  {
    key: "plymouth",
    title: "Plymouth",
    url: "https://www.rightmove.co.uk/house-prices/plymouth.html?country=england&searchLocation=Plymouth",
    latitude: 50.3715,
    longitude: -4.1429,
  },
  {
    key: "portsmouth",
    title: "Portsmouth",
    url: "https://www.rightmove.co.uk/house-prices/portsmouth.html?radius=0.25",
    latitude: 50.8038,
    longitude: -1.0753,
  },
  {
    key: "preston",
    title: "Preston",
    url: "https://www.rightmove.co.uk/house-prices/preston.html?country=england&searchLocation=Preston",
    latitude: 53.7593,
    longitude: -2.6992,
  },
  {
    key: "reading",
    title: "Reading",
    url: "https://www.rightmove.co.uk/house-prices/reading.html?country=england&searchLocation=Reading",
    latitude: 51.455188,
    longitude: -0.979525,
  },
  {
    key: "ripon",
    title: "Ripon",
    url: "https://www.rightmove.co.uk/house-prices/ripon.html?radius=1.0",
    latitude: 54.136,
    longitude: -1.5235,
  },
  {
    key: "salisbury",
    title: "Salisbury",
    url: "https://www.rightmove.co.uk/house-prices/salisbury.html?radius=0.25",
    latitude: 51.0683,
    longitude: -1.7949,
  },
  {
    key: "sheffield",
    title: "Sheffield",
    url: "https://www.rightmove.co.uk/house-prices/sheffield.html?country=england&searchLocation=Sheffield",
    latitude: 53.3796,
    longitude: -1.4701,
  },
  {
    key: "southampton",
    title: "Southampton",
    url: "https://www.rightmove.co.uk/house-prices/southampton.html?country=england&searchLocation=Southampton",
    latitude: 50.9025,
    longitude: -1.404,
  },
  {
    key: "southend-on-sea",
    title: "Southend",
    url: "https://www.rightmove.co.uk/house-prices/southend-on-sea.html?country=england&searchLocation=Southend-On-Sea",
    latitude: 51.5369,
    longitude: 0.7141,
  },
  {
    key: "st-albans",
    title: "St Albans",
    url: "https://www.rightmove.co.uk/house-prices/st-albans.html?radius=0.25",
    latitude: 51.7536,
    longitude: -0.3389,
  },
  {
    key: "stirling",
    title: "Stirling",
    url: "https://www.rightmove.co.uk/house-prices/stirling.html?radius=0.25",
    latitude: 56.1184,
    longitude: -3.9359,
  },
  {
    key: "stoke-on-trent",
    title: "Stoke",
    url: "https://www.rightmove.co.uk/house-prices/stoke-on-trent.html?country=england&searchLocation=Stoke-On-Trent",
    latitude: 53.016,
    longitude: -2.1818,
  },
  {
    key: "sunderland",
    title: "Sunderland",
    url: "https://www.rightmove.co.uk/house-prices/sunderland.html?country=england&searchLocation=Sunderland",
    latitude: 54.9056,
    longitude: -1.3819,
  },
  {
    key: "swansea",
    title: "Swansea",
    url: "https://www.rightmove.co.uk/house-prices/swansea.html?country=england&searchLocation=Swansea",
    latitude: 51.6193,
    longitude: -3.9475,
  },
  {
    key: "wakefield",
    title: "Wakefield",
    url: "https://www.rightmove.co.uk/house-prices/wakefield.html?radius=0.25",
    latitude: 53.6833,
    longitude: -1.4969,
  },
  {
    key: "winchester",
    title: "Winchester",
    url: "https://www.rightmove.co.uk/house-prices/winchester.html?radius=0.25",
    latitude: 51.0606,
    longitude: -1.3118,
  },
  {
    key: "wolverhampton",
    title: "Wolverhampton",
    url: "https://www.rightmove.co.uk/house-prices/wolverhampton.html?country=england&searchLocation=Wolverhampton",
    latitude: 52.5851,
    longitude: -2.1279,
  },
  {
    key: "worcester",
    title: "Worcester",
    url: "https://www.rightmove.co.uk/house-prices/worcester.html?country=england&radius=0.25&searchLocation=Worcester",
    latitude: 52.191,
    longitude: -2.2204,
  },
  {
    key: "wrexham",
    title: "Wrexham",
    url: "https://www.rightmove.co.uk/house-prices/wrexham.html?radius=0.25",
    latitude: 53.0474,
    longitude: -2.9945,
  },
  {
    key: "york",
    title: "York",
    url: "https://www.rightmove.co.uk/house-prices/york.html?country=england&searchLocation=York",
    latitude: 53.9601,
    longitude: -1.0822,
  },
]

export default locations
