import CountUp from "react-countup"
import { useNavigate, useSearchParams } from "react-router-dom"
import AuxiliaryButton from "../Button/AuxiliaryButton"
import BlueButton from "../Button/BlueButton"
import data from "../../data/result.json"

export default function PlayedModalBody({ onClose, location, day }) {
  const currentResults = JSON.parse(localStorage.getItem("results")) ?? {}

  const currentLocResults = currentResults?.[day]?.[location] ?? []

  const property = data[day][location][0]

  return (
    <div>
      <div className="flex flex-col space-y-8">
        <div>
          <p>
            You&apos;ve already played this location today. You can see your
            result below.
            <div className="flex flex-col items-center justify-center mt-4">
              <p className="text-3xl font-['Alfa_Slab_One'] text-gray-800">
                {currentLocResults[0]?.amountOff}
              </p>
              <p className="font-semibold text-gray-500">Amount Off</p>
            </div>
          </p>
          <p className="mt-4">
            We update properties every 24 hours. In the meantime why not try one
            of our other locations?
          </p>
        </div>
      </div>
      <div className="flex justify-center mt-8 space-x-4">
        {property && (
          <a target="_blank" rel="noreferrer" href={property?.url}>
            <AuxiliaryButton>View On Rightmove</AuxiliaryButton>
          </a>
        )}
        <BlueButton onClick={onClose}>Done</BlueButton>
      </div>
    </div>
  )
}
