import CountUp from "react-countup"
import { useNavigate, useSearchParams } from "react-router-dom"
import AuxiliaryButton from "../Button/AuxiliaryButton"
import BlueButton from "../Button/BlueButton"
import locations from "../../data/locations"

export default function ResultModalBody({
  actual = 0,
  guess = 0,
  onClose,
  day,
}) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const selectedLoc = searchParams.get("location")

  const onSubmit = () => {
    onClose()
    navigate(`/locations`)
  }

  const location = locations.filter((l) => l.key === selectedLoc)

  const currentResults = JSON.parse(localStorage.getItem("results")) ?? {}

  const currentLocResults = currentResults?.[day]?.[selectedLoc] ?? []

  if (!location || currentLocResults.length < 1)
    return (
      <div>
        <h1>Something went wrong</h1>
      </div>
    )

  const totalOff = currentLocResults
    .map((r) => r.actualAmountOff)
    .reduce((a, b) => Math.abs(a) + Math.abs(b), 0)

  const avgOff = Math.round(totalOff / 5)

  // const resultText = `Propertyguessr ${
  //   location[0].title
  // } Day ${day}\n\nAverage: £${avgOff.toLocaleString("en-UK")}\n1. ${
  //   currentLocResults[0]?.amountOff
  // }\n2. ${currentLocResults[1]?.amountOff}\n3. ${
  //   currentLocResults[2]?.amountOff
  // }\n4. ${currentLocResults[3]?.amountOff}\n5. ${
  //   currentLocResults[4]?.amountOff
  // }\npropertyguessr.fun/play?location=${selectedLoc}`

  const firstResults = currentLocResults[0]

  const resultText = `🏠 Propertyguessr ${location[0].title} Day ${day}\nAmount Off: ${firstResults.amountOff}\n\nhttps://propertyguessr.fun/play?location=${selectedLoc}&src=self`

  return (
    <div>
      <div className="flex flex-col space-y-8">
        {/* <div className="flex flex-col items-center justify-center">
          <p className="mb-2 text-3xl font-['Alfa_Slab_One'] text-gray-800">
            <CountUp end={avgOff} separator="," prefix="£" />
          </p>
          <p className="font-semibold text-gray-500">Average Amount Off</p>
        </div> */}
        <p>
          A new property for this location will be posted tomorrow. Until then
          you can share your score your friends below.
        </p>
        <textarea
          readOnly
          id="message"
          rows="6"
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
          spellCheck="false"
          value={resultText}
        />
      </div>
      <div className="flex justify-center mt-8 space-x-4">
        <AuxiliaryButton
          onClick={() => navigator.clipboard.writeText(resultText)}
        >
          Copy To Clipboard
        </AuxiliaryButton>
        <BlueButton onClick={onSubmit}>Done</BlueButton>
      </div>
    </div>
  )
}
