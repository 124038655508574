import { MapContainer, TileLayer, Marker } from "react-leaflet"
import "leaflet/dist/leaflet.css"
import markerIconSelected from "../../assets/blueMarker.png"
import { Icon } from "leaflet"

export default function MapModalBody({ coords }) {
  return (
    <div className="bg-red-500 h-full">
      <div style={{ height: "500px" }} id="map">
        <MapContainer
          center={[coords.lat, coords.long]}
          zoom={13}
          scrollWheelZoom={true}
          className="h-full"
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            position={[coords.lat, coords.long]}
            icon={
              new Icon({
                iconUrl: markerIconSelected,
                iconSize: [36, 36],
                iconAnchor: [18, 36],
              })
            }
          ></Marker>
        </MapContainer>
      </div>
    </div>
  )
}
