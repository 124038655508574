/* eslint-disable react/prop-types */
import classnames from "classnames"
import FeaturesModalBody from "./FeaturesModalBody"
import FloorplanModalBody from "./FloorplanModalBody"
import GuessModalBody from "./GuessModalBody"
import MapModalBody from "./MapModalBody"
import ResultModalBody from "./ResultModalBody"
import PlayedModalBody from "./PlayedModalBody"

const ModalHeader = ({ title, onClose }) => {
  return (
    <div className="flex items-start justify-between p-4 border-b rounded-t bg-blue-700">
      <h3 className="text-2xl text-gray-100 font-['Alfa_Slab_One']">{title}</h3>
      {onClose && (
        <button
          onClick={onClose}
          type="button"
          className="text-gray-400 bg-transparent hover:hover:bg-blue-800 hover:text-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          data-modal-toggle="defaultModal"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
      )}
    </div>
  )
}

export default function Modal({
  modalState,
  onClose,
  property,
  guess,
  index,
  setModalState,
  setGuess,
  location,
  day,
}) {
  const notCloseable = modalState === "guess" || modalState === "result"
  const renderBody = () => {
    {
      if (modalState === "features") {
        return (
          <>
            <ModalHeader title="Key Features" onClose={onClose} />
            <div className="p-4 space-y-6">
              <FeaturesModalBody items={property.keyFeatures} />
            </div>
          </>
        )
      } else if (modalState === "floorplan") {
        return (
          <>
            <ModalHeader title="Floorplan" onClose={onClose} />
            <div className="p-6 space-y-6">
              <FloorplanModalBody
                images={property.floorplans.map((f) => f.url)}
              />
            </div>
          </>
        )
      } else if (modalState === "map") {
        return (
          <>
            <ModalHeader title="Map" onClose={onClose} />
            <div className="p-4 space-y-6">
              <MapModalBody
                coords={{ lat: property.latitude, long: property.longitude }}
              />
            </div>
          </>
        )
      } else if (modalState === "guess") {
        return (
          <>
            <ModalHeader title="Your Guess" />
            <div className="p-6 space-y-6">
              <GuessModalBody
                guess={guess}
                actual={property?.transaction?.price}
                index={index}
                onClose={onClose}
                setModalState={setModalState}
                setGuess={setGuess}
                url={property.url}
                day={day}
              />
            </div>
          </>
        )
      } else if (modalState === "result") {
        return (
          <>
            <ModalHeader title="Your Result" />
            <div className="p-6 space-y-6">
              <ResultModalBody
                guess={guess}
                actual={property?.transaction?.price}
                index={index}
                onClose={onClose}
                setModalState={setModalState}
                day={day}
              />
            </div>
          </>
        )
      } else if (modalState === "played") {
        return (
          <>
            <ModalHeader title="Whoops" onClose={onClose} />
            <div className="p-6 space-y-6">
              <PlayedModalBody
                guess={guess}
                actual={property?.transaction?.price}
                index={index}
                onClose={onClose}
                setModalState={setModalState}
                location={location}
                day={day}
              />
            </div>
          </>
        )
      }
    }
  }

  return (
    <div
      id="defaultModal"
      tabIndex="-1"
      aria-hidden="true"
      className={classnames(
        "fixed top-0 left-0 right-0 z-[10000] w-full overflow-x-hidden overflow-y-auto inset-0 h-full",
        { hidden: !modalState }
      )}
    >
      <div
        className="relative w-full h-full max-w-lg m-auto flex items-center px-6 bg-black/40"
        onClick={notCloseable ? () => {} : onClose}
      >
        <div
          className="relative bg-white rounded-lg shadow w-full"
          onClick={(ev) => ev.stopPropagation()}
        >
          {renderBody()}
        </div>
      </div>
    </div>
  )
}
