import { Routes, Route } from "react-router-dom"
import Root from "./routes/root"
import Locations from "./routes/locations"
import OldLocations from "./routes/old_locations"
import Game from "./routes/game"
import App2 from "./App2"

function App() {
  return (
    <Routes>
      <Route path="/" element={<Root />} />
      <Route path="/locations" element={<Locations />} />
      {/* <Route path="/old_locations" element={<OldLocations />} /> */}
      <Route path="/play" element={<Game />} />
      {/* <Route path="/old" element={<App2 />} /> */}
    </Routes>
  )
}

export default App
