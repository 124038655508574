const BlueButton = ({ onClick, children, className, primary }) => (
  <button
    onClick={onClick}
    type="button"
    className={`text-gray-100 bg-blue-700 hover:bg-blue-800 focus:ring-4
    focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  focus:outline-none ${className}`}
  >
    {children}
  </button>
)

export default BlueButton
