import classNames from "classnames"

export default function DetailBox({ title, text, leftAlign }) {
  return (
    <div className="flex flex-col justify-end">
      <p
        className={classNames("font-['Alfa_Slab_One'] text-gray-800", {
          "text-center": !leftAlign,
        })}
      >
        {text}
      </p>
      <p className="text-gray-500 font-medium">{title}</p>
    </div>
  )
}
