/* eslint-disable react/prop-types */
import Carousel from "../components/Carousel"
import data from "../data/result.json"
import Modal from "../components/Modal/Modal"
import { useEffect, useState } from "react"
import DetailBox from "../components/DetailBox"
import IconButton from "../components/Button/IconButton"
import GameHeader from "../components/GameHeader"
import { useSearchParams } from "react-router-dom"
import { NumericFormat } from "react-number-format"
import day from "../data/day"

export default function Game() {
  const [searchParams] = useSearchParams()

  const location = searchParams.get("location")
  const urlDay = searchParams.get("day") ?? day

  const index = searchParams.get("index") ?? 0

  const [modalState, setModalState] = useState(null)
  const [guess, setGuess] = useState(undefined)
  const onClose = () => setModalState(null)

  const property = data[urlDay][location][index]

  const hasFeatures = (property?.keyFeatures ?? []).length > 0
  const hasFloorplans = (property?.floorplans ?? []).length > 0

  return (
    <>
      <div className="bg-black w-full absolute inset-0 md:py-8">
        <div className="m-0 mx-auto max-w-lg h-full">
          <div className="flex flex-col h-full bg-white">
            <GameHeader />
            <div className="">
              <Carousel images={property.images} />
            </div>
            <div className="px-6 bg-white">
              <div className="flex justify-between py-2 pb-3 border-b border-gray-200">
                <DetailBox
                  title="Property Type"
                  text={property?.propertySubType ?? "N/A"}
                  leftAlign
                />
                <DetailBox
                  title="Tenure"
                  text={property?.transaction?.tenure ?? "N/A"}
                />
                <DetailBox
                  title="Bedrooms"
                  text={property?.bedrooms ?? "N/A"}
                />
              </div>
              <div className="flex justify-between py-2 pb-3 border-b border-gray-200">
                <div className="flex h-12 w-full space-x-2">
                  <IconButton
                    disabled={!hasFloorplans}
                    onClick={() => setModalState("floorplan")}
                  >
                    <svg
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    >
                      <path d="M24 23h-20c-2.208 0-4-1.792-4-4v-15.694c.313-1.071 1.285-2.306 3-2.306 1.855 0 2.769 1.342 2.995 2.312l.005 1.688h18v18zm-2-16h-16v11s-.595-1-1.922-1c-1.104 0-2.078.896-2.078 2s.896 2 2 2h18v-14zm-2 12h-12v-10h12v10zm-8-9h-3v8h10v-4h-2v3h-1v-3h-3v3h-1v-7zm-8-6.339c-.233-.921-1.807-.917-2 0v11.997c.408-.421 1.383-.724 2-.658v-11.339zm9 6.339v3h6v-3h-6z" />
                    </svg>
                  </IconButton>
                  <IconButton onClick={() => setModalState("map")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 3c2.131 0 4 1.73 4 3.702 0 2.05-1.714 4.941-4 8.561-2.286-3.62-4-6.511-4-8.561 0-1.972 1.869-3.702 4-3.702zm0-2c-3.148 0-6 2.553-6 5.702 0 3.148 2.602 6.907 6 12.298 3.398-5.391 6-9.15 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm8 6h-3.135c-.385.641-.798 1.309-1.232 2h3.131l.5 1h-4.264l-.344.544-.289.456h.558l.858 2h-7.488l.858-2h.479l-.289-.456-.343-.544h-2.042l-1.011-1h2.42c-.435-.691-.848-1.359-1.232-2h-3.135l-4 8h24l-4-8zm-12.794 6h-3.97l1.764-3.528 1.516 1.528h1.549l-.859 2zm8.808-2h3.75l1 2h-3.892l-.858-2z" />
                    </svg>
                  </IconButton>
                  <IconButton
                    disabled={!hasFeatures}
                    onClick={() => setModalState("features")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M13 16h-2v-6h2v6zm-1-10.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25zm0-2.75c5.514 0 10 3.592 10 8.007 0 4.917-5.145 7.961-9.91 7.961-1.937 0-3.383-.397-4.394-.644-1 .613-1.595 1.037-4.272 1.82.535-1.373.723-2.748.602-4.265-.838-1-2.025-2.4-2.025-4.872-.001-4.415 4.485-8.007 9.999-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.418.345 2.775.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006z" />
                    </svg>
                  </IconButton>
                </div>
              </div>
              <div className="flex space-x-4 mt-4">
                <NumericFormat
                  prefix={"£"}
                  allowLeadingZeros
                  thousandSeparator=","
                  allowNegative={false}
                  id="large-input"
                  value={guess}
                  onChange={(ev) => setGuess(ev.target.value)}
                  onKeyDown={(ev) => {
                    if (guess && ev.key === "Enter") {
                      setModalState("guess")
                    }
                  }}
                  maxLength={10}
                  className="block text-3xl w-full p-4 text-gray-800 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 font-['Alfa_Slab_One']"
                />
                <button
                  onClick={() => {
                    if (!guess) return
                    setModalState("guess")
                  }}
                  type="button"
                  className="text-gray-100 md:whitespace-nowrap bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-lg text-lg px-8 py-4 focus:outline-none"
                >
                  <span className="hidden md:inline">Submit </span>
                  Guess
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        modalState={modalState}
        onClose={onClose}
        property={property}
        guess={(guess ?? "").replaceAll(",", "").replace("£", "")}
        index={index}
        setModalState={setModalState}
        setGuess={setGuess}
        day={urlDay}
      />
    </>
  )
}
