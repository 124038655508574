export default function FeaturesModalBody({ items }) {
  return (
    <div>
      <ul className="space-y-2 max-w-md list-disc relative mr-0 ">
        {items.map((i) => (
          <li className="mx-4" key={i}>
            {i}
          </li>
        ))}
      </ul>
    </div>
  )
}
