import { ReactComponent as Logo } from "../assets/home-question.svg"
import { Link } from "react-router-dom"

function GameHeader() {
  return (
    <div className="px-4 py-4 flex items-center justify-center xs:space-x-6 bg-[url('../public/bg-houses.png')] bg-center bg-fixed">
      <Link to="/">
        <Logo className="xs:block hidden w-10 h-10 xs:w-12 xs:h-12" />
      </Link>
      <h1 className="text-gray-100 xs:mt-3 font-['Alfa_Slab_One'] text-3xl md:text-4xl">
        PROPERTYGUESSR
      </h1>
    </div>
  )
}

export default GameHeader
