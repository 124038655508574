import { Link, useNavigate } from "react-router-dom"
import locations from "../data/locations"
import { MapContainer } from "react-leaflet"
import LocationMap from "../components/LocationMap"
import { useState } from "react"
import BlueButton from "../components/Button/BlueButton"
import GameHeader from "../components/GameHeader"
import ReactResizeDetector from "react-resize-detector"
import Modal from "../components/Modal/Modal"
import day from "../data/day"

export default function Locations() {
  const [modalState, setModalState] = useState(null)
  const [location, setLocation] = useState(null)

  const onClose = () => setModalState(null)
  const navigate = useNavigate()

  const [selectedLocation, setSelectedLocation] = useState({
    key: "london",
    title: "London",
    url: "https://www.rightmove.co.uk/house-prices/london-87490.html?country=england&searchLocation=London",
    latitude: 51.5045,
    longitude: -0.1291,
  })

  const onSubmit = () => {
    const currentResults = JSON.parse(localStorage.getItem("results")) ?? {}

    const hasResultForLocation = currentResults?.[day]?.[selectedLocation?.key]

    if (hasResultForLocation) {
      setLocation(selectedLocation.key)
      setModalState("played")
    } else {
      navigate(`/play?location=${selectedLocation?.key}&day=${day}`)
    }
  }

  return (
    <>
      <div className="bg-black w-full absolute inset-0 md:py-8" id="test">
        <div className="m-0 mx-auto max-w-lg flex flex-col bg-[url('../public/bg-houses.png')] bg-center h-full">
          <GameHeader />
          <ReactResizeDetector handleHeight>
            {({ height }) => (
              <div className="h-full">
                {height && (
                  <div style={{ height: height }} className={`bg-white`}>
                    <MapContainer
                      center={[54, -2.2]}
                      zoom={6}
                      scrollWheelZoom={true}
                      className="h-full"
                    >
                      <LocationMap
                        locations={locations}
                        setSelectedLocation={setSelectedLocation}
                        selectedLocation={selectedLocation}
                        day={day}
                      />
                    </MapContainer>
                  </div>
                )}
              </div>
            )}
          </ReactResizeDetector>
          <div className="px-4 py-6 flex flex-col items-center md:min-h-[168px]">
            <h1 className="text-4xl mb-8 font-['Alfa_Slab_One'] text-gray-100">
              {selectedLocation?.title}
              <sub className="pl-2 text-xs font-medium text-gray-100 font-sans">
                ({selectedLocation?.latitude},{selectedLocation?.longitude})
              </sub>
            </h1>
            <div>
              {/* <Link to={`/play?location=${selectedLocation?.key}`}> */}
              <BlueButton onClick={onSubmit}>Select Location</BlueButton>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
      <Modal
        modalState={modalState}
        onClose={onClose}
        property={null}
        index={0}
        setModalState={setModalState}
        location={location}
        setGuess={() => {}}
        day={day}
      />
    </>
  )
}
